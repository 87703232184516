import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import SimpleHero from "../components/SimpleHero";
import Column from "../components/Column";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class About extends Component {
   render() {

    const {
      data: { seo, hero, aboutCol },
    } = this.props

    const seoData = seo.primary;

    const heroData = hero.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container about-page">
          <NavBar />
          <SimpleHero
            title={ heroData.about_page_title.text 
            }
            teaser={ heroData.about_page_teaser.text }
            />
          <div className="about-section-wrapper">
            {aboutCol.items.map((c, i) => {
              const colData = c.page_column.document.data;
              return (
                <Column
                  title={ colData.column_title.text }
                  paragraph={ colData.column_paragraphs.map((p, i) => {
                    return (
                      <p className="about-column-paragraph text-reg">
                        { p.paragraph.text }
                      </p>
                    )
                  })}
                  />
              )
            })}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
   }
}

export default About

About.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        about_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        about_page_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    aboutCol: PropTypes.shape({
      items: PropTypes.array.isRequired
    }),
  }),
}

export const aboutQuery = graphql`
  query aboutPage {

    seo: prismicAboutPageBody1SeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicAboutPage {
      data {
        about_page_title {
          text
        }
        about_page_teaser {
          text
        }
      }
    }

    aboutCol: prismicAboutPageBodyParagraphColumn {
      items {
        page_column {
          document {
            ... on PrismicPageColumn {
              id
              data {
                column_title {
                  text
                }
                column_paragraphs {
                  paragraph {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }
`