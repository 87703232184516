import React from "react";
import "./style.css";

export default ({ title, paragraph }) => (
  <React.Fragment>
    <div className="about-column-wrapper">
      <h3 className="about-column-title text-med">
        { title }
      </h3>
      <div>
        { paragraph }
      </div>
    </div>
  </React.Fragment>
)